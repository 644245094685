<template>
  <div id="employer-layout">
    <KSubMenu
      :menu-items="menuItems"
      :parent-menu="parentMenu"
    />
    <RouterView
      v-if="employer"
      :employer="employer"
      :get-employer="getEmployer"
    />
  </div>
</template>

<script>

import Employer from '@/application/models/Employer.js';
import KSubMenu from '@/components/menu/KSubMenu.vue';
import { show } from '@/modules/employer/api/employer.js';

export default {
  name: 'EmployerLayout',
  components: {
    KSubMenu,
  },
  data: () => ({
    employer: null,
  }),
  computed: {
    menuItems() {
      return [
        {
          title: this.$t('global.dossier'),
          route: { name: 'employer.show' },
        },
        {
          title: this.$tc('person.title', 2),
          route: { name: 'employer.persons' },
        },
        {
          title: this.$tc('document.title', 2),
          route: { name: 'employer.documents' },
          permission: 'document.show',
        },
        {
          title: this.$tc('location.title', 2),
          route: { name: 'employer.location.index' },
          permission: 'location.show',
        },
        {
          title: this.$tc('exclusion.title', 2),
          route: { name: 'employer.exclusion' },
          permission: 'exclusion.show',
        },
        {
          title: this.$tc('employerSettings.title', 2),
          route: { name: 'employer.settings' },
          permission: 'employer.show',
        },
        {
          title: this.$tc('priceAgreement.title', 2),
          route: { name: 'employer.priceAgreement' },
          permission: 'priceAgreement.show',
        },
        {
          title: this.$tc('placement.title', 2),
          route: { name: 'employer.placement' },
          permission: 'employer.show',
        },
        {
          title: this.$tc('invoice.title', 2),
          route: { name: 'employer.invoices' },
          permission: 'employer.show',
        },
      ];
    },
    parentMenu() {
      return [
        {
          title: this.$tc('employer.title', 1),
          route: { name: 'employer.index' },
        },
      ];
    },
  },
  watch: {
    '$route.params.employerId': {
      immediate: true,
      async handler() {
        await this.getEmployer();
      },
    },
  },
  methods: {
    async getEmployer() {
      const response = await show(this.$route.params.employerId);
      this.employer = new Employer().mapResponse(response.data.data);
    },
  },
};
</script>
